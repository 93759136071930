import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/noLimitImg.jpg"

function Bems() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap bemsWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap bemsOverview">
          <div className="textWrap">
            <h2>건물에너지관리시스템(BEMS)</h2>
            <p className="bodyText">
              설비(조명, 냉‧난방설비, 환기설비, 콘센트 등)에 센서와 계측장비를
              설치하고 통신망으로 연계하여,
              <br />
              ㅇ 에너지원별, 용도별 등의 상세 사용량을 실시간으로 모니터링하고,
              <br />
              ㅇ 수집된 에너지 사용 정보를 S/W를 통해 데이터분석하고 설비의
              자동제어를 통해 운영 최적화, 비용 감소, 에너지 절감을 하는 통합
              관리 시스템입니다.
              <br />
              <br />
              각종 설비기기에 대한 단순한 상태감시(정상가동 유무 등)와 단편적인
              자동 또는 수동 제어 중심이었던 기존의 '유사건물관리 시스템'을,
              '17.1.1. 이후 정부 주도로 에너지이용 합리화를 추진하여 일정 면적
              이상의 공공기관 건축물에 EMS시스템 사용을 의무화 하였습니다.
              상업건물의 대형화와 노후 건물의 재건축등으로 새로운 건추 시대를
              맞이한 때에, ELSA Platform은 에너지 관리와 효율적인 감시업무에
              필요한 다양한 기능들을 제공하고 설계,건축 후 30년 이상 사용하는
              장기자산인 건물을 운영하기에 필요한 유지관리 비용(평균 71%)을
              효과적으로 줄여줍니다.
            </p>
          </div>
        </div>
        <div className="sectionWrap fobSectionWrap bemsBreakdown">
          <div className="textWrap">
            <h2>Elsa platform의 BEMS 업무 기능</h2>
          </div>
          <ul>
            <li>
              <p className="title">에너지 진단 관리</p>
              <p className="element">
                에너지 낭비 진단
                <br />
                설비 운영상태 진단
                <br />
                설비 성능향상 방안
                <br />
                에너지 낭비 개선
                <br />
                의사결정지원
              </p>
            </li>
            <li>
              <p className="title">에너지 모니터링</p>
              <p className="element">
                에너지 모니터링
                <br />
                설비 모니터링
                <br />
                환경 모니터링
              </p>
            </li>
            <li>
              <p className="title">통계 분석</p>
              <p className="element">
                에너지 경향분석
                <br />
                설비 성능분석
                <br />
                에너지 비교분석
                <br />
                에너지 통계분석
              </p>
            </li>
            <li>
              <p className="title">예측/최적화 관리</p>
              <p className="element">
                에너지 사용예측
                <br />
                에너지 사용최적화
              </p>
            </li>
            <li>
              <p className="title">스마트 운영</p>
              <p className="element">
                지속가능경영 보고
                <br />
                운영개선 방안
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}
export default Bems
